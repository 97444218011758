<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="order_type"
        clearable
        placeholder="请选择订单类型"
        @change="getRefundOrderLists()"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <a href="https://api.jq56.com/api/admin/exportPayExcel" target="_blank">
        <el-button type="success" style="margin-left: 10px"
          >导出退款订单表格</el-button
        >
      </a>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="user_id" label="用户id"> </el-table-column>
      <el-table-column prop="orderNo" label="订单号"> </el-table-column>
      <el-table-column prop="money" label="金额"> </el-table-column>
      <el-table-column prop="chanid" label="支付通道">
        <template slot-scope="scope">
          <span v-if="scope.row.chanid === 1">微信</span>
          <span v-if="scope.row.chanid === 2">支付宝</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_type" label="订单类型"> </el-table-column>
      <el-table-column prop="refund_apply_time" label="退款申请时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.refund_apply_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="refund_finish_time" label="退款完成时间">
        <template v-if="scope.row.refund_finish_time" slot-scope="scope">
          {{ formatDate(scope.row.refund_finish_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="refund_review_time" label="退款审批时间">
        <template v-if="scope.row.refund_review_time" slot-scope="scope">
          {{ formatDate(scope.row.refund_review_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="refund_reason" label="退款申请原因">
      </el-table-column>
      <el-table-column prop="refund_refuse_reason" label="退款拒绝原因">
      </el-table-column>
      <el-table-column prop="refund_status" label="退款状态">
        <template slot-scope="scope">
          <span v-if="scope.row.refund_status === 0">待审核</span>
          <span v-if="scope.row.refund_status === 1">审批通过</span>
          <span v-if="scope.row.refund_status === 2">审批拒绝</span>
          <span v-if="scope.row.refund_status === 3">退款成功</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.refund_status === 0"
            type="success"
            @click="opendialog(scope.row.id)"
            v-loading.fullscreen.lock="fullscreenLoading"
            >审核</el-button
          >
          <el-button v-else type="success" disabled>审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 订单审核弹窗 -->
    <el-dialog title="订单审核" :visible.sync="dialogVisible" width="50%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <p>订单id：{{ orderInfo.id }}</p>
          <p>订单信息：{{ orderInfo.info }}</p>
          <p>订单号：{{ orderInfo.orderNo }}</p>
          <p>用户id：{{ orderInfo.user_id }}</p>
          <p>订单金额：{{ orderInfo.money }}</p>
          <p>订单创建时间：{{ formatDate(orderInfo.refund_apply_time) }}</p>
        </el-col>
        <el-col :span="12" :offset="0">
          <p>
            支付通道：
            <span v-if="orderInfo.chanid === 1">微信</span>
            <span v-if="orderInfo.chanid === 2">支付宝</span>
          </p>
          <p>退款申请时间：{{ formatDate(orderInfo.refund_apply_time) }}</p>
          <p>退款原因：{{ orderInfo.refund_reason }}</p>
          <p v-if="orderInfo.refund_review_time">
            退款审核时间：{{ formatDate(orderInfo.refund_review_time) }}
          </p>
          <p>退款拒绝原因：{{ orderInfo.refund_refuse_reason }}</p>
        </el-col>
      </el-row>
      <p style="font-weight: bolder">审核操作</p>
      <el-radio-group v-model="status">
        <el-radio :label="1">同意退款</el-radio>
        <el-radio :label="2">拒绝退款</el-radio>
      </el-radio-group>
      <el-input
        v-if="status === 2"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        style="margin-top: 10px"
        placeholder="请输入拒绝退款理由"
        v-model="refuseReason"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="reviewRefundOrders()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRefundOrderList,
  getRefundOrder,
  reviewRefundOrder,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      fullscreenLoading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      pageRange: 3, // 当前页前后显示的页数范围
      dialogVisible: false,
      rowid: "",
      orderInfo: {},
      status: "",
      refuseReason: "",
      options: [
        {
          value: "现场操作订单",
          label: "现场操作订单",
        },
        {
          value: "机力租赁订单",
          label: "机力租赁订单",
        },
        {
          value: "小推车搬运订单",
          label: "小推车搬运订单",
        },
      ],
      order_type: "",
    };
  },
  methods: {
    opendialog(id) {
      this.fullscreenLoading = true;
      const token = localStorage.getItem("token");
      getRefundOrder(token, id).then((res) => {
        if (res.data.code === 200) {
          this.orderInfo = res.data.data;
          this.dialogVisible = true;
          this.fullscreenLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.fullscreenLoading = false;
        }
      });
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getRefundOrderLists();
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // getRefundOrderLists() {
    //   this.loading = true;
    //   const token = localStorage.getItem("token");
    //   getRefundOrderList(token, this.order_type, this.page, this.list).then(
    //     (res) => {
    //       if (res.data.code === 200) {
    //         this.tableData = res.data.data.data;
    //         this.total = res.data.data.total;
    //         this.loading = false;
    //       } else {
    //         this.$message.error(res.data.msg);
    //         this.loading = false;
    //       }
    //     }
    //   );
    // },
    handlePageChange(page) {
      this.page = page;
      this.getRefundOrderLists(); // 请求新页面的数据
    },
    getRefundOrderLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getRefundOrderList(token, this.order_type, this.page, this.pageSize).then(
        (res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
            console.log(this.orderList);
          } else {
            this.$message.error("获取配置失败！" + res.data.msg);
            this.loading = false;
          }
        }
      );
    },
    reviewRefundOrders() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      reviewRefundOrder(
        token,
        this.orderInfo.id,
        this.status,
        this.refuseReason
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("审核成功");
          this.getRefundOrderLists();
          this.dialogVisible = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.getRefundOrderLists();
  },
  // computed: {
  //   // 动态计算需要显示的页码并过滤
  //   filteredPages() {
  //     const pages = [];
  //     const startPage = Math.max(2, this.currentPage - this.pageRange);
  //     const endPage = Math.min(
  //       this.total - 1,
  //       this.currentPage + this.pageRange
  //     );

  //     for (let i = startPage; i <= endPage; i++) {
  //       pages.push(i);
  //     }
  //     return pages;
  //   },
  //   // 是否显示左边的省略号
  //   showLeftEllipsis() {
  //     return this.currentPage - this.pageRange > 2;
  //   },
  //   // 是否显示右边的省略号
  //   showRightEllipsis() {
  //     return this.currentPage + this.pageRange < this.total - 1;
  //   },
  // },
};
</script>
