<template>
  <div>
    <el-button
      @click="openAddDialog()"
      type="success"
      style="margin: 0 0 10px 10px"
      >添加展馆</el-button
    >
    <el-button
      @click="openAddVenuesDialog()"
      type="success"
      style="margin: 0 0 10px 10px"
      >批量添加展馆</el-button
    >
    <el-input
      v-model="key"
      placeholder="请输入要搜索的展馆名称"
      clearable
      style="width: 250px; margin-left: 10px"
      @change="getVenues()"
    >
    </el-input>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="exhibition_name" label="展会名称">
      </el-table-column>
      <el-table-column prop="label" label="展馆名称"> </el-table-column>
      <!-- <el-table-column prop="employee_name" label="负责员工"> </el-table-column>
      <el-table-column prop="employee_phone" label="员工电话">
      </el-table-column> -->
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope"
          ><span v-show="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span></template
        >
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope"
          ><span v-show="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span></template
        >
      </el-table-column>
      <el-table-column label="操作" width="310">
        <template slot-scope="scope">
          <!-- <el-button @click="openEpDialog(scope.row.id)" type="success"
            >查看负责员工</el-button
          > -->
          <el-button @click="openEditDialog(scope.row)" type="primary"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delVenues(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getVenues()"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>

          <!-- 显示第一页 -->
          <div
            @click="changePage(1)"
            :class="{ 'active-page': currentPage === 1 }"
            class="page-button"
          >
            1
          </div>

          <!-- 显示省略号 -->
          <span v-if="showLeftEllipsis">...</span>

          <!-- 显示当前页附近的页码 -->
          <div
            v-for="page in filteredPages"
            :key="page"
            @click="changePage(page)"
            :class="{ 'active-page': currentPage === page }"
            class="page-button"
          >
            {{ page }}
          </div>

          <!-- 显示省略号 -->
          <span v-if="showRightEllipsis">...</span>

          <!-- 显示最后一页 -->
          <div
            @click="changePage(total)"
            :class="{ 'active-page': currentPage === total }"
            class="page-button"
          >
            {{ total }}
          </div>

          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 添加dialog -->
    <el-dialog title="添加展馆" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="展馆名称">
          <el-input v-model="addLable" placeholder="请输入展馆名称"></el-input>
        </el-form-item>
      </el-form>
      <p>请选择归属展会</p>
      <el-radio-group v-model="addEid" v-for="item in enameData" :key="item.id">
        <el-radio :label="item.eid">{{ item.label }}</el-radio>
      </el-radio-group>
      <!-- <p>请选择负责员工</p>
      <el-input
        placeholder="请输入员工名称"
        v-model="employeeName"
        @change="searchEmployees()"
      ></el-input>
      <el-radio-group
        v-model="employeeId"
        v-for="item in employeeList"
        :key="item.id"
      >
        <el-radio :label="item.id"
          >{{ item.nickname }}&nbsp;{{ item.phone }}</el-radio
        >
      </el-radio-group> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVenues()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量添加dialog -->
    <el-dialog
      title="批量添加展馆"
      :visible.sync="AddDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="展馆名称">
          <el-input
            v-model="addLables"
            placeholder="请输入展馆名称，多个展馆之间使用逗号隔开"
          ></el-input>
        </el-form-item>
      </el-form>
      <p>请选择归属展会</p>
      <el-radio-group
        v-model="addEids"
        v-for="item in enameData"
        :key="item.id"
      >
        <el-radio :label="item.eid">{{ item.label }}</el-radio>
      </el-radio-group>
      <!-- <p>请选择负责员工</p>
      <el-input
        placeholder="请输入员工名称"
        v-model="employeeName"
        @change="searchEmployees()"
      ></el-input>
      <el-radio-group
        v-model="employeeId"
        v-for="item in employeeList"
        :key="item.id"
      >
        <el-radio :label="item.id"
          >{{ item.nickname }}&nbsp;{{ item.phone }}</el-radio
        >
      </el-radio-group> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVenueLists()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑dialog -->
    <el-dialog title="编辑展馆" :visible.sync="updateDialogVisible" width="30%">
      <el-form>
        <el-form-item label="展馆名称">
          <el-input
            v-model="updateLable"
            placeholder="请输入展馆名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateVenues()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看负责员工dialog -->
    <el-dialog title="查看负责员工" :visible.sync="epDialog" width="80%">
      <el-button type="success" @click="addEpDialog = true"
        >添加负责员工</el-button
      >
      <el-table :data="epData" style="width: 100%">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="vid" label="归属展馆id"> </el-table-column>
        <el-table-column prop="employee_name" label="负责员工姓名">
        </el-table-column>
        <el-table-column prop="employee_phone" label="负责员工电话">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定删除该员工吗？"
              @confirm="dropEmployeeForVids(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="epDialog = false">取 消</el-button>
        <el-button type="primary" @click="epDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加负责员工dialog -->
    <el-dialog title="添加负责员工" :visible.sync="addEpDialog" width="30%">
      <el-form>
        <el-form-item label="负责员工电话">
          <el-input
            v-model="addEp.phone"
            placeholder="请输入员工电话"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addEpDialog = false">取 消</el-button>
        <el-button type="primary" @click="addEmployeeForVids()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addEmployeeForVid,
  addVenueList,
  dropEmployeeForVid,
  getEmployeeForVid,
} from "@/api/api";
import {
  addVenue,
  delVenue,
  getEnameList,
  getVenue,
  updateVenue,
  searchEmployee,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      enameData: [],
      loading: false,
      dialogVisible: false,
      updateDialogVisible: false,
      AddDialogVisible: false,
      addEid: "",
      addLable: "",
      addValue: "",
      addMoney: "",
      updateid: "",
      updateLable: "",
      updateValue: "",
      updateMoney: "",
      page: 1,
      list: 5,
      total: 0, // 总页数
      pageRange: 3, // 当前页前后显示的页数范围
      currentPage: 1,
      addEids: "",
      addLables: "",
      addValues: "",
      key: "",
      employeeList: [],
      employeeName: "",
      employeeId: "",
      epDialog: false,
      vid: "", //展会id
      addEp: [],
      addEpDialog: false,
    };
  },
  methods: {
    dropEmployeeForVids(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      dropEmployeeForVid(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.epDialog = false;
          this.getEmployeeForVids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    addEmployeeForVids() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addEmployeeForVid(token, this.vid, this.addEp.phone).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.addEpDialog = false;
          this.epDialog = false;
          this.getEmployeeForVids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    getEmployeeForVids() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      getEmployeeForVid(token, this.vid).then((res) => {
        if (res.data.code === 200) {
          this.epData = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openEpDialog(vid) {
      this.vid = vid;
      this.getEmployeeForVids();
      this.epDialog = true;
    },
    searchEmployees() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      searchEmployee(token, this.employeeName).then((res) => {
        if (res.data.code === 200) {
          this.employeeList = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openAddDialog() {
      this.getEnameLists();
      this.dialogVisible = true;
    },
    openAddVenuesDialog() {
      this.getEnameLists();
      this.AddDialogVisible = true;
    },
    openEditDialog(row) {
      this.updateid = row.id;
      this.updateLable = row.label;
      this.updateValue = row.value;
      this.updateDialogVisible = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getVenues();
    },
    getVenues() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getVenue(token, this.page, this.list, this.key).then((res) => {
        if (res.data.code === 200) {
          this.loading = false;
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        } else {
          this.loading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    delVenues(id) {
      delVenue(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getVenues();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addVenues() {
      this.addValue = this.addLable;
      addVenue(this.addEid, this.addLable, this.addValue, this.employeeId)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.dialogVisible = false;
            this.getVenues();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
    addVenueLists() {
      this.addValues = this.addLables;
      addVenueList(
        this.addEids,
        this.addLables,
        this.addValues,
        this.employeeId
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.AddDialogVisible = false;
          this.getVenues();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    updateVenues() {
      this.updateValue = this.updateLable;
      updateVenue(this.updateid, this.updateLable, this.updateValue).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.updateDialogVisible = false;
            this.getVenues();
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code === 200) {
          this.enameData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getVenues();
  },
  computed: {
    // 动态计算需要显示的页码并过滤
    filteredPages() {
      const pages = [];
      const startPage = Math.max(2, this.currentPage - this.pageRange);
      const endPage = Math.min(
        this.total - 1,
        this.currentPage + this.pageRange
      );

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    // 是否显示左边的省略号
    showLeftEllipsis() {
      return this.currentPage - this.pageRange > 2;
    },
    // 是否显示右边的省略号
    showRightEllipsis() {
      return this.currentPage + this.pageRange < this.total - 1;
    },
  },
};
</script>
