<template>
  <div>
    <div style="padding: 0 0 10px 10px">
      <el-input
        v-model="key"
        placeholder="请输入搜索关键字：支持昵称、手机号、用户名"
        style="width: 350px"
        @change="GetMembersLists()"
        clearable
      ></el-input>
      <el-button
        type="primary"
        @click="addDialogVisible = true"
        style="margin-left: 10px; margin-right: 10px"
        >添加</el-button
      >
      <!-- <el-button type="danger">批量删除</el-button> -->
      <span
        >当前积分兑换比率：{{ payRate * 100 }}%(1元= {{ payRate }}积分)</span
      >
      <el-button
        type="success"
        style="margin-left: 10px"
        @click="scoreDialogVisible = true"
        >修改</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
      :height="'calc(100vh - 150px)'"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="email" label="电子邮箱"> </el-table-column>
      <el-table-column prop="mobile" label="手机号"> </el-table-column>
      <el-table-column prop="avatar" label="头像">
        <template slot-scope="scope">
          <img
            :src="scope.row.avatar"
            :alt="avatar"
            class="custom-avatar"
            @error="errorHandler"
          />
          <!-- <el-avatar :src="scope.row.avatar" @error="errorHandler"></el-avatar> -->
          <!-- <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.avatar"
            :preview-src-list="[scope.row.avatar]"
          >
          </el-image> -->
        </template>
      </el-table-column>
      <el-table-column prop="level" label="等级"></el-table-column>
      <el-table-column prop="gender" label="性别"></el-table-column>
      <el-table-column prop="money" label="余额"></el-table-column>
      <el-table-column prop="score" label="积分"></el-table-column>
      <el-table-column
        prop="successions"
        label="连续登录天数"
      ></el-table-column>
      <el-table-column
        prop="maxsuccessions"
        label="最大连续登录天数"
      ></el-table-column>
      <el-table-column prop="prevtime" label="上次登录时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.prevtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="loginip" label="登录IP"></el-table-column>
      <el-table-column prop="joinip" label="注册IP"></el-table-column>
      <el-table-column prop="jointime" label="注册时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.jointime) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="账号状态">
        <template slot-scope="scope">
          <!-- normal下显示 -->
          <div v-if="scope.row.status === 'normal'">
            <el-tag type="success">正常</el-tag>
          </div>
          <!-- 其他情况下显示 -->
          <div v-if="scope.row.status != 'normal'">
            <el-tag type="danger">异常</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <template>
            <el-popconfirm
              title="删除后数据不可恢复！确认继续删除吗"
              @confirm="DeleteMembers(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 添加弹窗 -->
    <el-dialog title="添加会员" :visible.sync="addDialogVisible" width="70%">
      <!-- <el-row :gutter="20">
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="用户名">
              <el-input v-model="addData.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="addData.password"></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="addData.nickname"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input v-model="addData.email"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="手机号">
              <el-input v-model="addData.phone"></el-input>
            </el-form-item>
            <el-form-item label="头像">
              <el-input v-model="addData.avatar"></el-input>
            </el-form-item>
            <el-form-item label="等级">
              <el-input v-model="addData.level"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-model="addData.gender"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="余额">
              <el-input v-model="addData.money"></el-input>
            </el-form-item>
            <el-form-item label="积分">
              <el-input v-model="addData.scope"></el-input>
            </el-form-item>
            <el-form-item label="账号状态">
              <el-input v-model="addData.status"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row> -->
      <el-form>
        <el-form-item label="请输入用户名">
          <el-input v-model="username"></el-input>
        </el-form-item>
        <el-form-item label="请输入手机号">
          <el-input v-model="mobile"></el-input>
        </el-form-item>
        <el-form-item label="请输入密码">
          <el-input v-model="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddMembers()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑会员" :visible.sync="editDialogVisible" width="70%">
      <el-row :gutter="20">
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="用户名">
              <el-input v-model="editData.username"></el-input>
            </el-form-item>
            <!-- <el-form-item label="密码">
              <el-input v-model="editData.password"></el-input>
            </el-form-item> -->
            <el-form-item label="昵称">
              <el-input v-model="editData.nickname"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input v-model="editData.email"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="手机号">
              <el-input v-model="editData.mobile"></el-input>
            </el-form-item>
            <!-- <el-form-item label="头像">
              <el-input v-model="editData.avatar"></el-input>
            </el-form-item> -->
            <el-form-item label="等级">
              <el-input v-model="editData.level"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-model="editData.gender"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="余额">
              <el-input v-model="editData.money"></el-input>
            </el-form-item>
            <el-form-item label="积分">
              <el-input v-model="editData.score"></el-input>
            </el-form-item>
            <el-form-item label="账号状态">
              <el-input v-model="editData.status"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditMembers()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 积分修改弹窗 -->
    <el-dialog
      title="修改积分兑换比率"
      :visible.sync="scoreDialogVisible"
      width="30%"
    >
      <el-input
        v-model="payRate"
        placeholder="请输入积分比率"
        type="number"
        :maxlength="1"
        @input="numValid()"
      >
        <template slot="append">%</template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updatePayRates()">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "blueimp-md5";
import {
  AddMember,
  GetMembersList,
  EditMember,
  DeleteMember,
  getPayRate,
  updatePayRate,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      total: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      loading: false,
      addDialogVisible: false,
      editDialogVisible: false,
      addData: {},
      editData: {},
      editId: "",
      username: "",
      password: "",
      mobile: "",
      salt: "",
      key: "",
      nickname: "",
      payRate: "",
      scoreDialogVisible: false,
    };
  },
  methods: {
    numValid() {
      if (this.payRate > 1) {
        this.$message.error("最大仅支持1");
        this.payRate = 1;
        return;
      }
    },
    updatePayRates() {
      if (!this.payRate) {
        this.$message.error("请输入比率");
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在加载比率",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      updatePayRate(token, this.payRate).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.getPayRates();
          this.scoreDialogVisible = false;
          loading.close();
          return;
        }
        this.$message.error("修改失败" + res.data.msg);
        loading.close();
      });
    },
    getPayRates() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载比率",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getPayRate().then((res) => {
        if (res.data.code === 200) {
          this.payRate = res.data.data;
          loading.close();
          return;
        }
        loading.close();
        this.$message.error("加载比率失败" + res.data.msg);
      });
    },
    errorHandler(event) {
      const defaultAvatar = "https://api.jq56.com/image/default_avatar.png";
      if (event.target.src !== defaultAvatar) {
        console.warn(`头像加载失败，使用默认头像: ${event.target.src}`);
        event.target.src = defaultAvatar;
      }
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.GetMembersLists();
    },
    //打开编辑弹窗
    OpenEditDialog(row) {
      this.editData = row;
      this.editId = row.id;
      this.editData.password = "";
      this.editDialogVisible = true;
    },
    //时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // GetMembersLists() {
    //   const token = localStorage.getItem("token");
    //   GetMembersList(token, this.page, this.list).then((res) => {
    //     if (res.data.code === 200) {
    //       this.tableData = res.data.data.data;
    //       this.total = res.data.data.total;
    //     } else {
    //       this.$message.error("获取数据失败，请刷新页面重试");
    //     }
    //   });
    // },
    handlePageChange(page) {
      this.page = page;
      this.GetMembersLists(); // 请求新页面的数据
    },
    GetMembersLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      GetMembersList(token, this.page, this.pageSize, this.key).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    AddMembers() {
      // this.username = this.mobile;
      const token = localStorage.getItem("token");
      AddMember(token, this.username, this.password, this.mobile).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功");
            this.addDialogVisible = false;
            this.GetMembersLists();
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    // 比较并提取修改的字段
    getModifiedFields(original, current) {
      let modified = {};
      for (let key in current) {
        if (current[key] !== original[key]) {
          modified[key] = current[key];
        }
      }
      return modified;
    },

    // 哈希加密方法
    hashPassword(password, salt) {
      return md5(md5(password) + salt);
    },

    // 编辑成员的方法
    EditMembers() {
      const token = localStorage.getItem("token");
      let modifiedData = this.getModifiedFields(
        this.originalEditData,
        this.editData
      );

      // 对密码字段进行加密，并分开上传加密后的密码和盐值
      if (this.editData.password) {
        const hashedPassword = this.hashPassword(
          this.editData.password,
          this.editData.salt
        );
        modifiedData.password = hashedPassword;
        modifiedData.salt = this.editData.salt;
      }

      EditMember(token, this.editId, modifiedData).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.editDialogVisible = false;
          this.GetMembersLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    DeleteMembers(id) {
      DeleteMember(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetMembersLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetMembersLists();
    this.getPayRates();
  },
  // 在打开编辑弹窗时记录初始数据
  mounted() {
    this.$watch("editDialogVisible", (newVal) => {
      if (newVal) {
        this.originalEditData = { ...this.editData }; // 深拷贝初始数据
      }
    });
  },
};
</script>

<style>
.custom-avatar {
  width: 50px; /* 根据需求设置宽高 */
  height: 50px;
  border-radius: 50%; /* 圆形头像 */
  object-fit: cover; /* 图片按比例缩放并裁剪 */
  border: 1px solid #ccc; /* 可选，增加边框样式 */
}
</style>
